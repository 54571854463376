// src/pages/Home.js

import React from "react";

const Home = () => {
  return (
    <div className="text-center py-16">
      <h1 className="text-6xl font-bold">WELCOME HOME</h1>
    </div>
  );
};

export default Home;
